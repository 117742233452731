import React from 'react';
import { NavLink } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';

const NotFound = () => {
    return (
        <div>
            <Header />
            <section className='section-notfound'>
                <h1>Error 404</h1>
                <p>Cette page n'existe pas</p>
                <p>Cliquez sur le bouton si dessous pour revenir à la page d'accueil</p>
                <NavLink to="/" className={(nav) => (nav.isActive) ? "nav-active" : ""}>
                    <button>Accueil</button>
                </NavLink>
            </section>
            <Footer />
        </div>
    );
};

export default NotFound;