import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Services = () => {
    const [transformValueRight, setTransformValueRight] = useState("1500px");
    const [transformValueLeft, setTransformValueLeft] = useState("-1500px");
    const [transformValueTop, setTransformValueTop] = useState("-1500px");
    const [isPageVisible, setIsPageVisible] = useState(false);

    useEffect(() => {
        setIsPageVisible(true);
        return () => setIsPageVisible(false);
    }, []);

    useEffect(() => {
        setTransformValueTop("0");
        setTransformValueRight("0");
        setTransformValueLeft("0");

    }, [isPageVisible]);
    return (
        <div>
            <Header />
            <section className='container-services'>
                <h1 style={{
                    transform: `translateY(${transformValueTop})`,
                    transition: "transform 1s ease-out"
                }}>Services</h1>
                <div className="container-card">
                    <div className='card' style={{
                        transform: `translate(${transformValueLeft},${transformValueTop})`,
                        transition: "transform 1s ease-out"
                    }}>
                        <div className='img-1'></div>
                        <h2>Création et développement</h2>
                        <p>Nous commençons par une étude de projet approfondie pour comprendre vos besoins spécifiques, puis nous développons un site web sur mesure en utilisant les dernières technologies disponibles. Nous veillons à ce que le site web soit facilement administrable et nous offrons également un service d'assistance complet pour répondre à toutes les questions et résoudre rapidement les problèmes éventuels. Notre objectif est de vous fournir un site web de haute qualité qui vous permet de réaliser vos objectifs commerciaux et de vous démarquer dans votre marché.</p>
                    </div>
                    <div className='card' style={{
                        transform: `translate(${transformValueRight},${transformValueTop})`,
                        transition: "transform 1s ease-out"
                    }}>
                        <div className='img-2'></div>
                        <h2>Maintenance et refonte</h2>
                        <p>Nous proposons également des services de maintenance et de refonte de sites web existants. Nous pouvons aider à résoudre les problèmes techniques, à mettre à jour le contenu et à améliorer la sécurité du site. Si vous avez besoin de moderniser votre site web existant, nous pouvons vous aider à repenser la conception, à améliorer les performances et à ajouter de nouvelles fonctionnalités pour mieux répondre aux besoins de votre entreprise. Notre objectif est de vous aider à tirer le meilleur parti de votre présence en ligne en vous fournissant des solutions personnalisées et de haute qualité.</p>
                    </div>
                    <div className='card' style={{
                        transform: `translateX(${transformValueLeft})`,
                        transition: "transform 1s ease-out"
                    }}>
                        <div className='img-3'></div>
                        <h2>Domaine et hébergement</h2>
                        <p>Services d'hébergement web et de nom de domaine pour garantir la présence en ligne de votre entreprise. Nous vous aidons à trouver et enregistrer un nom de domaine qui reflète l'image de votre entreprise et qui soit facilement mémorisable. Nous fournissons également des services d'hébergement web sécurisés pour assurer que votre site web est toujours disponible et accessible. Notre objectif est de fournir une solution complète pour votre présence en ligne en veillant à ce que votre entreprise dispose d'un nom de domaine professionnel et d'un hébergement web adapté à vos besoins.</p>
                    </div>
                    <div className='card' style={{
                        transform: `translateX(${transformValueRight})`,
                        transition: "transform 1s ease-out"
                    }}>
                        <div className='img-4'></div>
                        <h2>Réferencement SEO</h2>
                        <p>Chez CreaWebDev, nous prenons en compte tous les aspects du développement de votre site, y compris le référencement. Chaque site web que nous créons est développé en gardant à l'esprit les meilleures pratiques de référencement, afin de garantir que votre site se classe bien dans les résultats de recherche. Nous utilisons des techniques éprouvées pour optimiser les titres, les balises méta, les descriptions, et bien plus encore. Avec notre approche axée sur le référencement, vous pouvez être sûr que votre site sera facilement accessible aux utilisateurs et sera bien positionné sur les résultats de recherche.</p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Services;