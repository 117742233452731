import React, { useEffect, useState } from 'react';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Contact = () => {
    const [transformValueTop, setTransformValueTop] = useState("-1500px");
    const [isPageVisible, setIsPageVisible] = useState(false);

    useEffect(() => {
        setIsPageVisible(true);
        return () => setIsPageVisible(false);
    }, []);

    useEffect(() => {
        setTransformValueTop("0");

    }, [isPageVisible]);
    return (
        <div>
            <Header />
            <section className='section-contact page-transition contact-page'>
                <h1 style={{
                    transform: `translateY(${transformValueTop})`,
                    transition: "transform 1s ease-in-out"
                }}>Contact</h1>
                <h2 style={{
                    transform: `translateY(${transformValueTop})`,
                    transition: "transform 1s ease-in-out"
                }}>Pour plus de renseignements, n'hésitez pas à nous contacter via ce formulaire.</h2>
                <ContactForm />
            </section>
            <Footer />
        </div >
    );
};

export default Contact;