import React, { useEffect, useState } from 'react';

const Loader = () => {
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress(progress => progress + 10);
        }, 200);

        setTimeout(() => {
            clearInterval(timer);
            setLoading(false);
            setProgress(100);
        }, 2000);

        return () => clearInterval(timer);
    }, []);

    if (loading) {
        return (
            <div className="loader">
                <div className="loader__spinner"></div>
                <div className="loader__progress">{progress}%</div>
            </div>
        );
    }

    return null;
};

export default Loader;