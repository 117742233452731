import React, { useEffect, useState } from "react";
import axios from "axios";

const ContactForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [errorForm, setErrorForm] = useState({});
    const [sendMail, setSendMail] = useState("");
    const [transformValueRight, setTransformValueRight] = useState("1500px");
    const [transformValueLeft, setTransformValueLeft] = useState("-1500px");
    const [transformValueTop, setTransformValueTop] = useState("-1500px");
    const [isPageVisible, setIsPageVisible] = useState(false);

    useEffect(() => {
        setIsPageVisible(true);
        return () => setIsPageVisible(false);
    }, []);

    useEffect(() => {
        setTransformValueTop("0");
        setTransformValueRight("0");
        setTransformValueLeft("0");

    }, [isPageVisible]);


    const validateForm = () => {
        let isValid = true;
        let errors = {};

        if (name.length < 3) {
            isValid = false;
            errors.name = "Le nom doit contenir au moins 3 caractères";
        }

        if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(email)) {
            isValid = false;
            errors.email = "L'adresse email n'est pas valide";
        }

        if (message.length < 10) {
            isValid = false;
            errors.message = "Le message doit contenir au moins 10 caractères";
        }
        if (!isChecked) {
            isValid = false;
            errors.checkbox = "Veuillez confirmer que vous n'êtes pas un robot.";
        }

        return { isValid, errors };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { isValid, errors } = validateForm();

        if (!isValid) {
            setErrorForm(errors);
            console.log(errors);
            return;
        }
        try {
            const response = await axios.post(
                "https://api.sendinblue.com/v3/smtp/email",
                {
                    sender: { name, email },
                    to: [{ email: "contact@creawebdev.fr" }],
                    subject: "Message du formulaire CreaWebDev",
                    htmlContent: message,
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "api-key": process.env.REACT_APP_SENDINGBLUE_EMAIL,
                    },
                }
            );
            console.log(response);
            setName("");
            setEmail("");
            setMessage("");
            setErrorForm({});
            setIsChecked(false);
            setSendMail("Votre message a été envoyé avec succès !");
        } catch (error) {
            console.error(error);
            setErrorForm({});
            setIsChecked(false);
            setSendMail("Une erreur est survenue lors de l'envoi de votre message.");
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div style={{
                transform: `translate(${transformValueRight},${transformValueTop})`,
                transition: "transform 1s ease-out"
            }}>
                <input
                    type="text"
                    id="name"
                    value={name}
                    placeholder="Nom"
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div>
            <div style={{
                transform: `translate(${transformValueLeft},${transformValueTop})`,
                transition: "transform 1s ease-out"
            }}>
                <input
                    type="email"
                    id="email"
                    value={email}
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div style={{
                transform: `translate(${transformValueRight},${transformValueTop})`,
                transition: "transform 1s ease-out"
            }}>
                <textarea
                    id="message"
                    value={message}
                    placeholder="Message"
                    onChange={(e) => setMessage(e.target.value)}
                    required
                />
            </div>

            <div className="checkbox" style={{
                transform: `translate(${transformValueLeft},${transformValueTop})`,
                transition: "transform 1s ease-out"
            }}>
                <input
                    type="checkbox"
                    id="checkbox"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                    title="Cochez cette case pour prouver que vous n'êtes pas un robot"
                // required
                />
            </div>
            <p>
                {Object.values(errorForm).length > 0
                    ? Object.values(errorForm).map((error, index) => (
                        <span key={index}>
                            {error}
                            <br />
                        </span>
                    ))
                    : sendMail}
            </p>
            <button type="submit">Envoyer</button>
        </form>
    );
};

export default ContactForm;