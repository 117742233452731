import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Home = () => {
    const [transformValueRight, setTransformValueRight] = useState("1500px");
    const [transformValueLeft, setTransformValueLeft] = useState("-1500px");
    const [isPageVisible, setIsPageVisible] = useState(false);

    useEffect(() => {
        setIsPageVisible(true);
        return () => setIsPageVisible(false);
    }, []);

    useEffect(() => {
        setTransformValueRight("0");
        setTransformValueLeft("0");

    }, [isPageVisible]);
    return (
        <div>
            <Header />
            <section className='container-home page-transition home-page'>
                <div className="container">
                    <div className='text'>
                        <h1 style={{
                            transform: `translateX(${transformValueRight})`,
                            transition: "transform 1s ease-out"
                        }} >Création et maintenance de sites web</h1>
                        <h2 style={{
                            transform: `translateX(${transformValueRight})`,
                            transition: "transform 1s ease-out"
                        }} >Hébergement et référencement naturel (SEO)</h2>
                        <p style={{
                            transform: `translateX(${transformValueLeft})`,
                            transition: "transform 1s ease-out"
                        }}>Vous cherchez à créer un site web professionnel pour votre entreprise ou votre projet personnel ? Vous êtes au bon endroit ! Nous proposons des services de développement de site web de qualité pour répondre à vos besoins en ligne.</p>
                        <p style={{
                            transform: `translateX(${transformValueRight})`,
                            transition: "transform 1s ease-out"
                        }}>En tant que développeur web expérimenté, nous travaillons en étroite collaboration avec vous pour créer un site web sur mesure qui répond à vos objectifs et vos spécifications. Nous sommes spécialisés dans le développement de sites web dynamiques et interactifs, qui offrent une expérience utilisateur fluide et engageante.</p>
                        <p style={{
                            transform: `translateX(${transformValueLeft})`,
                            transition: "transform 1s ease-out"
                        }}>Passionnés par le développement de sites web de haute qualité, nous nous engageons à fournir des solutions sur mesure pour chaque projet. Que vous cherchiez à créer un site web pour vendre vos produits en ligne, présenter vos services, ou pour tout autre besoin, nous sommes là pour vous aider.</p>
                        <p style={{
                            transform: `translateX(${transformValueRight})`,
                            transition: "transform 1s ease-out"
                        }}>Nous utilisons les dernières technologies web pour créer des sites web qui sont à la fois beaux et performants. Nous nous assurons également que tous nos sites sont conformes aux normes d'accessibilité en ligne pour garantir que votre site est facilement utilisable pour tous les utilisateurs.</p>
                        <p style={{
                            transform: `translateX(${transformValueLeft})`,
                            transition: "transform 1s ease-out"
                        }}>De plus, nous proposons un service de maintenance et de support pour garantir que votre site web reste à jour et fonctionne correctement. Nous travaillons avec vous à chaque étape du processus pour nous assurer que votre site web répond à vos attentes et à celles de votre public cible.</p>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Home;