import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/img/logo.svg';

const Header = () => {
    const logoRef = useRef(null);
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        const body = document.body;

        const base = (e) => {
            if (!clicked) {

                var x = e.pageX / window.innerWidth - 0.5;
                var y = e.pageY / window.innerHeight - 0.5;
                logoRef.current.style.transform = `
                perspective(1250px)
                rotateX(${y * 60 + 0}deg)
                rotateZ(${-x * 30 + 0}deg)
                translateZ(0vw)
                `;
            }
        };

        body.addEventListener("pointermove", base);

        return () => {
            body.removeEventListener("pointermove", base);
        };
    }, [clicked]);

    const stopMove = () => {
        logoRef.current.style.transform = "none";
        setClicked(true);
    };

    return (
        <header>
            <nav>
                <ul>
                    <li><NavLink to="/" className={(nav) => (nav.isActive) ? "nav-active" : ""}>accueil</NavLink></li>
                    <li><NavLink to="/services" className={(nav) => (nav.isActive) ? "nav-active" : ""}>services</NavLink></li>
                    <li><NavLink to="/contact" className={(nav) => (nav.isActive) ? "nav-active" : ""}>contact</NavLink></li>
                </ul>
            </nav>
            <div className='container-logo' onClick={stopMove}>
                <img src={logo} className="logo" alt="Logo de CreaWebDev" width={383} height={261} ref={logoRef} />
            </div>
        </header>
    );
};

export default Header;