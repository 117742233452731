import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loader from './components/Loader';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Services from './pages/Services';
import NotFound from './pages/NotFound';
import MentionsLegales from './pages/MentionsLegales';
import CGV from './pages/CGV';

function App() {
  return (
    <BrowserRouter>
      <Loader />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/conditions-generales-de-vente" element={<CGV />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
