import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <footer>
            <div className="footer-container">
                <div className="footer-left">
                    <h3>contact</h3>
                    <a href="tel:0756874471" title="Cliquez ici pour ouvrir votre gestionnaire d'appel par defaut"><FontAwesomeIcon icon={faPhone} /> 07 56 87 44 71</a>
                    <a href="mailto:contact@creawebdev.fr" title="Cliquez ici pour ouvrir votre gestionnaire d'email par defaut"><FontAwesomeIcon icon={faEnvelope} /> contact@creawebdev.fr</a>
                    <a href="https://www.google.com/maps/place/Bd+Bischoffsheim,+06300+Nice/@43.7141781,7.2940331,16.75z/data=!4m6!3m5!1s0x12cdc5324465ac7f:0x25a49fd49838ad0d!8m2!3d43.7141178!4d7.2966542!16s%2Fg%2F1tf918sr?hl=fr" target="_blank" rel="noreferrer" title="Attention, CreaWebDev ne possède pas de bureau ouvert au public."><FontAwesomeIcon icon={faLocationDot} /> Boulevard Bischoffsheim, 06300 Nice</a>
                </div>
                <div className="footer-right">
                    <h3>à propos</h3>
                    <NavLink to="/mentions-legales">
                        Mentions légales
                    </NavLink>
                    <NavLink to="/conditions-generales-de-vente">
                        Conditions Générales de Vente
                    </NavLink>
                </div>
            </div>
            <div className='footer-bottom'>
                Copyright© 2023 CreaWebDev.fr
            </div>
        </footer >
    );
};

export default Footer;